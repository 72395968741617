import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Navbar from './components/navbar/component.jsx';

import './index.css';

const RegistrationPage = Loadable({
    loader: () => import(/* webpackChunkName: "RegistrationPage" */ './pages/RegistrationPage'),
    loading: () => <div></div>,
    modules: ['RegistrationPage'],
});

function App(props) {
    return (
        <React.Fragment>
            <Navbar />
            <BrowserRouter basename={'/registration'}>
                <Switch>
                    <Route exact path="/" component={RegistrationPage} />
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;

