export const HANDLE_URL_CHANGE = 'HANDLE_URL_CHANGE'
export const HANDLE_TOKEN_CHANGE = 'HANDLE_TOKEN_CHANGE'
export const HANDLE_WEBSOCKET_URL_CHANGE = 'HANDLE_WEBSOCKET_URL_CHANGE'

export const handleUrlChange = (value) => (
  { type: HANDLE_URL_CHANGE, value }
);

export const handleTokenChange = (value) => (
  { type: HANDLE_TOKEN_CHANGE, value }
);

export const handleWebSocketUrlChange = (value) => (
  { type: HANDLE_WEBSOCKET_URL_CHANGE, value }
);
