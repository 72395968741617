import {
    createStore,
    applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'
import reducer from './reducer';

// if you're also using redux-thunk, add it as a middleware
const createStoreWithMiddleware = composeWithDevTools(
    applyMiddleware(ReduxThunk)
  )(
    createStore
  );

export default function configureStore(initialState = {}) {
    return createStoreWithMiddleware(reducer, initialState);
};
