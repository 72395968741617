import React from 'react';
import { NavLink } from 'react-router-dom'
import "./style.scss";

const NavBar = props => {
    return (
        <div className="navbar-basic__container">
            <span className="navbar-basic__logo-title"><NavLink to="/" exact activeClassName="active">Registration</NavLink></span>
        </div>
    );
}

export default NavBar;