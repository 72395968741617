import {
    HANDLE_URL_CHANGE,
    HANDLE_TOKEN_CHANGE,
    HANDLE_WEBSOCKET_URL_CHANGE,
} from '../actions/registrationActions'

const registration = (
  state = {
    url: "",
    token: "",
    websocketUrl: ""
  },
  action
) => {
  switch (action.type) {
    case HANDLE_URL_CHANGE:
        return { ...state, 
          url: action.value
        }
    case HANDLE_TOKEN_CHANGE:
        return { ...state, 
          token: action.value
        }
    case HANDLE_WEBSOCKET_URL_CHANGE:
        return { ...state, 
          websocketUrl: action.value
        }
    default:
      return state
  }
}

export default registration
  